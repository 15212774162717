import React from 'react'
import {Router} from '@reach/router'
import Layout from 'components/layout'
import AddCampaign from 'components/campaigns/add'
import EditCampaign from 'components/campaigns/edit'
import CampaignEmailBody from 'components/campaigns/emailBody'
import Dashboard from 'components/dashboard'
import PrivateRoute from 'components/PrivateRoute'
import * as container from 'styles/components/container.module.css'

const App = () => {
  return (
    <Layout>
      <Router basepath="/app" className={container.containerInner}>
        <PrivateRoute path="/campaigns/add" component={AddCampaign} />
        <PrivateRoute path="/campaigns/edit/:uuid" component={EditCampaign} />
        <PrivateRoute path="/campaigns/:uuid/:type" component={CampaignEmailBody} />
        <PrivateRoute path="/" component={Dashboard} />
      </Router>
    </Layout>
  )
}

export default App
