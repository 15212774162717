import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Field, FieldArray} from 'formik'
import FormField from 'components/formField'
import * as formStyles from 'styles/components/form.module.css'
import * as styles from './styles.module.css'

const EmailHeadersFields = ({values}) => (
  <FieldArray name="email_headers">
    {({remove, push}) => (
      <Fragment>
        {values['email_headers'].length > 0 && (
          values['email_headers'].map((_header, index) => (
            <div key={index} className={styles.headersFieldSet}>
              <Field
                label="Key"
                name={`email_headers.${index}.key`}
                type="text"
                component={FormField}
              />
              <Field
                label="Value"
                name={`email_headers.${index}.value`}
                type="text"
                component={FormField}
              />
              <div>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className={formStyles.deleteKeyValueButton}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        )}
        <button
          type="button"
          onClick={() => push({key: '', value: ''})}
          className={styles.addHeadersButton}
        >
            Add Header
        </button>
      </Fragment>
    )}
  </FieldArray>
)

EmailHeadersFields.propTypes = {
  values: PropTypes.object.isRequired
}

export default EmailHeadersFields
