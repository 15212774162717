import React from 'react'
import {Link} from 'gatsby'
import CampaignsList from 'components/campaigns/list'
import * as buttonStyles from 'styles/components/button.module.css'
import * as styles from './styles.module.css'

const Dashboard = () => {
  return (
    <div>
      <div className={styles.dashboardHeading}>
        <h2 className={styles.dashboardTitle}>Dashboard</h2>
        <Link to="/app/campaigns/add" className={buttonStyles.primaryButton}>Add campaign</Link>
      </div>
      <CampaignsList />
    </div>
  )
}

export default Dashboard
