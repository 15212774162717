const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

const smtpSettings = {
  production: [
    {
      title: 'Production | Account: MT Campaigns (#821992) | Domain: mailtrap.io -> Transactional',
      key: 'mailtrap_campaigns_smtp'
    },
    {
      title: 'Production | Account: MT D14y Alerts (#1112305) | Domain: mailtrap.io -> Transactional',
      key: 'mailtrap_campaigns_alerts_smtp'
    },
    {
      title: 'Production | Account: MT Campaigns (#821992) | Domain: mailtrap.email -> Transactional',
      key: 'transactional_mailtrap_campaigns_smtp_email'
    },
    {
      title: 'Production | Account: MT Campaigns (#821992) | Domain: mailtrap.io -> Bulk',
      key: 'bulk_mailtrap_campaigns_smtp'
    },
    {
      title: 'Production | Account: MT D14y Alerts (#1112305) | Domain: mailtrap.io -> Bulk',
      key: 'bulk_mailtrap_campaigns_alerts_smtp'
    },
    {
      title: 'Production | Account: MT Campaigns (#821992) | Domain: mailtrap.email -> Bulk',
      key: 'bulk_mailtrap_campaigns_smtp_email'
    },
    {
      title: 'Production | Account: MT Campaigns (#821992) | Inbox: "campaigns.mailtrap.info"',
      key: 'mailtrap_campaigns_second_smtp'
    },
    {
      title: 'Production | Account: MT D14y Alerts (#1112305) | Inbox: "Campaigns Production"',
      key: 'mailtrap_campaigns_testing_alerts_smtp'
    }
  ],
  staging: [
    {
      title: 'Staging (?) | Transactional Sending | Account: julia.romanenkova@mailtrap.io',
      key: 'mailtrap_campaigns_smtp'
    },
    {
      title: 'Staging (?) | Bulk Sending | Account: julia.romanenkova@mailtrap.io',
      key: 'bulk_mailtrap_campaigns_smtp'
    },
    {
      title: 'Production | Account: MT Campaigns (#821992) | Inbox: "campaigns-staging.mailtrap.info"',
      key: 'mailtrap_campaigns_second_smtp'
    },
    {
      title: 'Production | Account: MT D14y Alerts (#1112305) | Inbox: "Campaigns Staging"',
      key: 'mailtrap_campaigns_alerts_smtp'
    }
  ],
  development: [
    {
      title: 'Development',
      key: 'mailtrap_campaigns_smtp'
    }
  ]
}

export const getSmtpSettings = () => smtpSettings[activeEnv]
