import * as Yup from 'yup'

export const formValidationSchema = Yup.object().shape({
  templateData: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required('Required'),
        value: Yup.string()
      })
    )
})
