import React from 'react'
import {useSelector} from 'react-redux'
import {getCampaignsSelector} from 'selectors/campaigns'
import * as tableStyles from 'styles/components/table.module.css'
import CampaignListItem from './item'

const CampaignsList = () => {
  const isLoading = useSelector(({campaigns}) => campaigns.isLoading)
  const campaigns = useSelector(getCampaignsSelector)

  if (!campaigns.length && isLoading) {
    return (
      <div>Loading...</div>
    )
  }

  if (!campaigns.length) {
    return (
      <div>No campaigns</div>
    )
  }

  return (
    <table className={tableStyles.table}>
      <thead>
        <tr>
          <th>UUID</th>
          <th>Name</th>
          <th>Status</th>
          <th colSpan="2">Period</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.map((campaign) => (
          <CampaignListItem
            key={campaign.uuid}
            campaign={campaign}
          />
        ))}
      </tbody>
    </table>
  )
}

export default CampaignsList
