import React from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import _keys from 'lodash/keys'
import _values from 'lodash/values'
import _isObject from 'lodash/isObject'
import * as tableStyles from 'styles/components/table.module.css'
import * as styles from './styles.module.css'

const TablePreview = ({results}) => {
  const getValue = (el) => {
    const value = _isObject(el) && el.value ? el.value : el
    if (!value) {
      return 'none'
    }

    return value
  }

  return (
    <div className={styles.resultTable}>
      <table className={tableStyles.table}>
        <thead>
        <tr>
          {_keys(results[0]).map((key) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {results.map((item) => (
          <tr key={item.id}>
            {_values(item).map((el, index) => (
              <td key={index}>
                {getValue(el)}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}

TablePreview.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object)
}

export default TablePreview
