import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Controlled as CodeMirror} from 'react-codemirror2'
import FormField from 'components/formField'
import * as codeFieldStyles from './styles.module.css'
import {isBrowser} from 'lib/global'

if (isBrowser()) {
  require('codemirror/mode/htmlmixed/htmlmixed')
  require('codemirror/mode/sql/sql')
  require('codemirror/addon/display/placeholder')
}

const FormCodeField = ({
  viewMode,
  form,
  field,
  disabled,
  lineNumbers = true,
  theme = 'default',
  placeholder,
  className,
  ...rest
}) => {
  const {name, value} = field
  const {setFieldValue} = form

  const handleChange = (_editor, _data, value) => setFieldValue(name, value)

  const classes = classnames(className, codeFieldStyles.codeField)

  return (
    <FormField
      component={CodeMirror}
      options={{
        mode: viewMode,
        theme,
        lineNumbers,
        readOnly: disabled,
        placeholder,
        viewportMargin: Infinity
      }}
      {...rest}
      onBeforeChange={handleChange}
      form={form}
      field={{name, value}}
      className={classes}
    />
  )
}

FormCodeField.propTypes = {
  viewMode: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired
  }).isRequired
}

export default FormCodeField
