import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {Link} from 'gatsby'
import {useSelector, useDispatch} from 'react-redux'
import {
  deleteCampaignRequest,
  updateCampaignRequest
} from 'actions/campaigns'
import {dispatchInPromise} from 'utils/redux'
import {getIsCampaignBlockedSelector, getCampaignBlockedTimeSelector} from 'selectors/campaigns'
import Timer from 'components/timer'
import * as typeStyles from 'styles/components/typography.module.css'
import * as campaignsListStyles from './styles.module.css'


const CampaignsListItem = ({campaign}) => {
  const {
    uuid,
    enabled
  } = campaign

  const isBlocked = useSelector((state) => getIsCampaignBlockedSelector(state, uuid))

  const blockingTime = useSelector((state) => getCampaignBlockedTimeSelector(state, uuid))

  const dispatch = useDispatch()

  const onEnable = () => {
    if (window.confirm(`Do you really want to ${enabled ? 'disable' : 'enable'}?`)) {
      dispatchInPromise(dispatch, updateCampaignRequest, {uuid, data: {uuid, 'enabled': !enabled}})
    }
  }

  const onDelete = () => {
    if (window.confirm('Do you really want to delete this campaign?')) {
      dispatch(deleteCampaignRequest({uuid}))
    }
  }

  return (
    <Fragment>
      <tr>
        <td className={campaignsListStyles.campaignId}>{campaign.uuid}</td>
        <td className={campaignsListStyles.campaignName}>{campaign.name}</td>
        <td>
          {campaign.enabled ? (
            <span className={typeStyles.success}>
              Enabled
            </span>
          ) : (
            <span className={typeStyles.warning}>
              Disabled
            </span>
          )}
        </td>
        <td>
          <div className={campaignsListStyles.labelBlock}>{campaign.period}</div>
          ({dayjs(campaign.next_perform_at).format('YYYY-MM-DD HH:mm:ss Z')})
        </td>
        <td className={campaignsListStyles.campaignControlsCell}>
          {isBlocked && (
            <div className={campaignsListStyles.campaignTimerBlock}>
              <Timer timeDiff={blockingTime} /> mins left
            </div>
          )}
          <button
            className={campaignsListStyles.campaignControl}
            onClick={onEnable}
            disabled={isBlocked}
          >
              {campaign.enabled ? 'Disable' : 'Enable'}
          </button>
          <Link
            to={`/app/campaigns/edit/${uuid}`}
            className={`${campaignsListStyles.campaignControl} ${isBlocked ? 'disabled' : ''}`}
          >
            Edit
          </Link>
          <button
            className={campaignsListStyles.campaignControl}
            onClick={onDelete}
            disabled={isBlocked}
          >
              Delete
          </button>
        </td>
      </tr>
      <tr>
        <td colSpan="5" className={campaignsListStyles.campaignSqlCell}>
          <code>{campaign.sql_query}</code>
        </td>
      </tr>
    </Fragment>
  )
}

CampaignsListItem.propTypes = {
  campaign: PropTypes.object
}

export default CampaignsListItem
