import React, {useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useInputWithData} from 'hooks/campaign'
import * as styles from './styles.module.css'

const DOM_MODIFICATOR_FOR_TYPE = {
  html: 'innerHTML',
  text: 'innerText',
  amp: 'innerHTML'
}

const EmailBodyIframe = ({emailBody, type, data = {}}) => {
  const ref = useRef()
  const resultedData = useInputWithData(data, emailBody)

  useEffect(() => {
    const iframe = ref.current
    if (!iframe) {
      return
    }

    const document = iframe.contentDocument
    const modificator = DOM_MODIFICATOR_FOR_TYPE[type]

    if (modificator) {
      document.body[modificator] = resultedData
    }
  }, [type, resultedData])

  return (
    <iframe ref={ref} title={`Email ${type} body`} className={styles.emailIframe}>
      <p>Your browser does not support iframes.</p>
    </iframe>
  )
}

EmailBodyIframe.propTypes = {
  emailBody: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.object
}

export default EmailBodyIframe
