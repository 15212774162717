import {createSelector} from 'reselect'
import createCachedSelector from 're-reselect'
import dayjs from 'dayjs'

export const BLOCKING_TIME_IN_MINS = 90

const getAllCampaignsIds = ({campaigns}) => campaigns.allIds || []
export const isCampaignsLoaded = ({campaigns}) => campaigns.isRequestLoaded
export const getCampaignsById = ({campaigns}) => campaigns.byUuid
export const getCampaignById = ({campaigns}, uuid) => campaigns.byUuid[uuid] || {}

export const getCampaignsSelector = createSelector([getAllCampaignsIds, getCampaignsById], (allIds, byUuid) =>
  allIds.map((id) => byUuid[id])
)

const getCampaignBlockedTimeDiff = (campaign) => {
  const currentDate = dayjs()
  const createdAtDate = campaign['created_at']?.value

  if (!createdAtDate) {
    return true
  }

  const diff = Math.floor(currentDate.diff(dayjs(createdAtDate), 'minute', true))

  return diff
}

const getIsCampaignBlocked = (campaign) => {
  const diff = getCampaignBlockedTimeDiff(campaign)

  return diff < BLOCKING_TIME_IN_MINS
}

export const getCampaignBlockedTimeSelector = createCachedSelector(
  [getCampaignById],
  (campaign) => getCampaignBlockedTimeDiff(campaign)
)((_state, uuid) => `campaign/${uuid}`)

export const getIsCampaignBlockedSelector = createCachedSelector(
  [getCampaignById],
  (campaign) => getIsCampaignBlocked(campaign)
)((_state, uuid) => `campaign/${uuid}`)

export const getRecipientsByCampaignId = ({sqlQuery}, uuid) => sqlQuery.byCampaignUuids[uuid] || []
