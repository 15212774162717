import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {Link} from 'gatsby'
import {Formik, Form, Field} from 'formik'
import CheckboxFormField from 'components/checkboxFormField'
import FormField from 'components/formField'
import FormSelect from 'components/formSelect'
import DatetimeFormField from 'components/datetimeFormField'
import * as formStyles from 'styles/components/form.module.css'
import * as buttonStyles from 'styles/components/button.module.css'
import FormCodeField from 'components/formCodeField'
import SqlQueryPreview from 'components/campaigns/sqlPreview'
import {addSchema} from './validationSchema'
import EmailBodyField from './emailBodyField'
import EmailHeadersFields from './emailHeadersFields'
import {emailInfoFieldsData, emailBodyFieldsData} from './fieldsData'
import {getSmtpSettings} from 'helpers/smtpSettings'
import * as styles from './styles.module.css'

const CampaignForm = ({initialValues, onSubmit}) => {
  const {uuid} = initialValues

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({values, isSubmitting, setFieldValue}) => (
        <Form noValidate={true}>
          <div className={formStyles.formControls}>
            <button type="submit" disabled={isSubmitting} className={buttonStyles.primaryButton}>
              Submit
            </button>
            <Link to="/app" className={buttonStyles.outlinedButton}>Cancel</Link>
          </div>

          <h3>General Info (state: {values.enabled ? 'enabled' : 'disabled'} {values.next_perform_at ? `, next perform at: ${dayjs(values.next_perform_at).format('YYYY-MM-DD HH:mm:ss Z')}` : ''})</h3>
          <div className={styles.fieldFourSet}>
            <Field label="Name" name="name" type="text" component={FormField} />
            <Field label="Period" name="period" component={FormSelect}>
              <option value="hourly">hourly</option>
              <option value="daily">daily</option>
              <option value="weekly">weekly</option>
              <option value="monthly">monthly</option>
              <option value="quarterly">quarterly (once per 3 months)</option>
              <option value="biannually">biannually (once per 6 months)</option>
              <option value="annually">annually (once per 12 months)</option>
            </Field>
            <Field label="SMTP server" name="smtp_credentials_key" component={FormSelect}>
              {getSmtpSettings().map((settings) => (
                <option key={settings.key} value={settings.key}>{settings.title}</option>
              ))}
            </Field>
            <Field
              label="Next Perform At"
              name="next_perform_at"
              setFieldValue={setFieldValue}
              component={DatetimeFormField}
            />
          </div>

          <h3>SQL</h3>
          <div className={styles.fieldSet}>
            <div className={styles.fieldSql}>
              <Field
                label="SQL query"
                name="sql_query"
                component={FormCodeField}
                viewMode="sql"
                className={styles.fieldSqlCode}
              />
              <Field
                placeholder="WITH campaign AS (#{sql_query}) SELECT * FROM campaign"
                label="QA SQL query"
                name="qa_sql_query"
                component={FormCodeField}
                viewMode="sql"
                className={styles.fieldSqlCode}
              />
              <p>
                <ul>
                  <li>
                    This query wraps SQL query as CTE: <code>WITH campaign AS (#&#123;sql_query&#125;) SELECT * FROM campaign</code>, so `campaign` is available to access results of the query.
                  </li>
                  <li>
                    QA Query is intended to look for invalid records in the original set.
                  </li>
                  <li>
                    Hence, if the SQL query loads customers on free plan only validate the campaign checking for different plan types:<code>SELECT * FROM campaign WHERE plan != 'free'</code>
                  </li>
                </ul>
              </p>
              <SqlQueryPreview uuid={uuid} query={values.sql_query} qaQuery={values.qa_sql_query} />
            </div>
            <Field label="Recepient Uniq Key" name="recepient_uniq_key" type="text" disabled={!!values.ignore_recepient_uniq_key} component={FormField} />
            <Field label="Recepient Email Key" name="recepient_email_key" type="text" component={FormField} />
            <Field label="Ignore Uniq Key (repeat recepients for campaigns)" name="ignore_recepient_uniq_key" type="checkbox" component={CheckboxFormField} />
          </div>

          <h3>Email Info</h3>
          <div className={styles.fieldSet}>
            {emailInfoFieldsData.map((field) => (
              <Field key={field.name} {...field} type="text" component={FormField} />
            ))}
          </div>

          <h3>Email Headers</h3>
          <EmailHeadersFields values={values} />

          <h3>Email Body</h3>
          {emailBodyFieldsData.map((field) => (
            <div key={field.name}>
              <EmailBodyField
                {...field}
                values={values}
                initialValues={initialValues}
              />
            </div>
          ))}

          <div className={formStyles.formControls}>
            <button type="submit" disabled={isSubmitting} className={buttonStyles.primaryButton}>
              Submit
            </button>
            <Link to="/app" className={buttonStyles.outlinedButton}>Cancel</Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

CampaignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default CampaignForm
