import * as Yup from 'yup'
import dayjs from 'dayjs'
import Mustache from 'mustache'

const validateMustache = (value, testContext) => {
  try {
    Mustache.parse(value)
    return true
  } catch (e) {
    return testContext.createError({message: e.toString()})
  }
}

const minDate = dayjs.utc().subtract(1, 'day').toDate()

export const addSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  period: Yup.string()
    .required('Required'),
  sql_query: Yup.string()
    .min(2, 'Too Short!')
    .required('Required'),
  ignore_recepient_uniq_key: Yup.boolean().nullable(),
  recepient_uniq_key: Yup.string().when('ignore_recepient_uniq_key', (ignoreRecepientUniqKey, schema) => (
    ignoreRecepientUniqKey ? schema : schema.required('Required')
  )),
  recepient_email_key: Yup.string()
    .required('Required'),
  email_subject: Yup.string()
    .required('Required'),
  email_from: Yup.string()
    .required('Required'),
  email_headers: Yup.array()
    .of(Yup.object().shape({
      key: Yup.string().required('Required'),
      value: Yup.string().required('Required')
  })),
  next_perform_at: Yup.date().min(minDate, 'Date cannot be in the past'),
  email_body_html: Yup.string().test(
    'is-valid-mustache',
    'is not valid mustache template',
    validateMustache
  ),
  email_body_text: Yup.string().test(
    'is-valid-mustache',
    'is not valid mustache template',
    validateMustache
  ),
  email_body_amp: Yup.string().test(
    'is-valid-mustache',
    'is not valid mustache template',
    validateMustache
  )
})
