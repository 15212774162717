export const emailInfoFieldsData = [
  {
    name: 'email_subject',
    label: 'Subject'
  },
  {
    name: 'email_from',
    label: 'From'
  },
  {
    name: 'email_reply_to',
    label: 'ReplyTo'
  }
]

export const emailBodyFieldsData = [
  {
    name: 'email_body_html',
    label: 'HTML',
    bodyType: 'html'
  },
  {
    name: 'email_body_text',
    label: 'Text',
    bodyType: 'text'
  },
  {
    name: 'email_body_amp',
    label: 'AMP',
    bodyType: 'amp'
  }
]
