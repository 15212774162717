import React from 'react'
import PropTypes from 'prop-types'
import _camelCase from 'lodash/camelCase'
import classnames from 'classnames'
import _get from 'lodash/get'
import * as styles from './styles.module.css'

const CheckboxFormField = (props) => {
  const {
    component: Component = 'input',
    form: {touched, errors},
    field,
    className,
    ...rest
  } = props
  const {name} = field
  const {label} = rest

  const randSufix = Math.random().toString(36).substring(2, 12)
  const controlID = _camelCase(`${name}ID${randSufix}`)
  const labelID = `${controlID}-label`
  const hasError = !!(_get(touched, name, null) && _get(errors, name, null))
  const a11yProps = label ? {'aria-labelledby': labelID} : {'aria-label': name}

  const componentClasses = classnames(className, styles.field, {
    hasError
  })

  return (
    <div className={styles.fieldRow}>
      <div className={styles.checkboxRow}>
        <Component
          id={controlID}
          className={componentClasses}
          {...a11yProps}
          {...field}
          {...rest}
        />
        {label && (
          <label htmlFor={controlID} id={labelID} className={styles.fieldLabel}>
            {label}
          </label>
        )}
      </div>
      {hasError && (
        <div className={styles.fieldError}>
          {_get(errors, name)}
        </div>
      )}
    </div>
  )
}

CheckboxFormField.propTypes = {
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string,
}

export default CheckboxFormField
