import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import * as styles from './styles.module.css'
import TablePreview from './tablePreview';

const QAResult = () => {
  const isLoading = useSelector(({qaSqlQuery}) => qaSqlQuery.isLoading)
  const hasError = useSelector(({qaSqlQuery}) => qaSqlQuery.hasError)
  const results = useSelector(({qaSqlQuery}) => qaSqlQuery.results)

  if (isLoading) {
    return (
      <div className={styles.resultContainer}>
        Running QA query for the campaign
      </div>
    )
  }

  if (hasError) {
    return (
      <div className={styles.resultContainer}>
        Something went wrong, the query might be invalid
      </div>
    )
  }

  if (!results.length) {
    return (
      <div className={styles.resultContainer}>
        The campaign looks good. No invalid records found
      </div>
    )
  }

  return (
    <div>
      <h3 className={styles.hasError}>Invalid records returned</h3>
      <TablePreview results={results} />
    </div>
  )
}

QAResult.propTypes = {
  uuid: PropTypes.string.isRequired
}

export default QAResult
