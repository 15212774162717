import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import {useSelector, useDispatch} from 'react-redux'
import {getCampaignById} from 'selectors/campaigns'
import {updateCampaignRequest} from 'actions/campaigns'
import {dispatchInPromise} from 'utils/redux'
import {getIsCampaignBlockedSelector} from 'selectors/campaigns'
import CampaignForm from './form'

const EditCampaign = ({uuid, navigate}) => {
  const dispatch = useDispatch()

  const campaign = useSelector((state) => getCampaignById(state, uuid))
  const isBlocked = useSelector((state) => getIsCampaignBlockedSelector(state, uuid))

  useEffect(() => {
    if (isBlocked) {
      navigate('/app', {replace: true})
    }
  }, [navigate, isBlocked])


  const onSubmit = (values, {setSubmitting, setErrors}) => {
    return dispatchInPromise(dispatch, updateCampaignRequest, {uuid, data: values})
      .finally(() => setSubmitting(false))
      .catch((errors) => setErrors(errors))
  }

  if (_isEmpty(campaign)) {
    return (
      <div>No campaign</div>
    )
  }

  return (
    <div>
      <h1>Edit Campaign</h1>
      <CampaignForm
        initialValues={campaign}
        onSubmit={onSubmit}
      />
    </div>
  )
}

EditCampaign.propTypes = {
  uuid: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default EditCampaign
