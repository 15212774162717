import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {Formik, Form, Field} from 'formik'
import FormCodeField from 'components/formCodeField'
import {updateCampaignRequest} from 'actions/campaigns'
import {dispatchInPromise} from 'utils/redux'
import * as styles from './styles.module.css'
import {emailBodyValidationSchema} from './emailBodyValidationSchema'

const EmailBodyForm = ({innerRef, initialValues, name, viewMode}) => {
  const {uuid} = initialValues
  const dispatch = useDispatch()

  const onSubmit = (values, {setErrors}) => {
    return dispatchInPromise(dispatch, updateCampaignRequest, {uuid, data: values})
      .catch((errors) => setErrors(errors))

  }

  return (
    <Formik
      innerRef={innerRef}
      initialValues={initialValues}
      validationSchema={emailBodyValidationSchema(name)}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({isSubmitting}) => (
        <Form className={styles.emailBodyForm}>
          <Field
            name={name}
            component={FormCodeField}
            viewMode={viewMode}
            disabled={isSubmitting}
            className={styles.emailBodyField}
          />
        </Form>
      )}
    </Formik>
  )
}

EmailBodyForm.propTypes = {
  innerRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default EmailBodyForm
