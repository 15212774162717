import React, {useState, useMemo} from 'react'
import PropTypes from 'prop-types'
import {useInterval} from 'hooks/useInterval'
import {BLOCKING_TIME_IN_MINS} from 'selectors/campaigns'

const Timer = ({timeDiff, delay = 60000}) => {
  const time = useMemo(() => (BLOCKING_TIME_IN_MINS - timeDiff), [timeDiff])

  const [count, setCount] = useState(time)

  useInterval(() => {
    if (!count) {
      return
    }

    setCount((count) => count - 1)
  }, delay)

  return (<span>{count}</span>)
}

Timer.propTypes = {
  timeDiff: PropTypes.number.isRequired,
  delay: PropTypes.number
}

export default Timer
