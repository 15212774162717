import * as Yup from 'yup'

export const formValidationSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Too Short!')
    .max(250, 'Too Long!')
    .email('Must be valid email address')
    .required('Required'),
  additionalItems: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required('Required'),
        value: Yup.string()
      })
    )
})
