import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {navigate} from 'gatsby'
import {useDispatch} from 'react-redux'
import {Field} from 'formik'
import {updateCampaignRequest} from 'actions/campaigns'
import {dispatchInPromise} from 'utils/redux'
import {getCodeHighlightMode} from 'helpers/campaigns'
import FormCodeField from 'components/formCodeField'
import * as buttonStyles from 'styles/components/button.module.css'
import * as styles from './styles.module.css'

const EmailBodyField = ({label, name, bodyType, values, initialValues}) => {
  const {uuid} = values
  const dispatch = useDispatch()

  const [isPreviewActive, setIsPreviewActive] = useState(true)

  const onEmailBodyPreviewClick = (value, type) => {
    if(initialValues[name] !== values[name]) {
      setIsPreviewActive(false)
      return dispatchInPromise(dispatch, updateCampaignRequest, {uuid, data: {[`email_body_${type}`]: value}})
        .finally(() => setIsPreviewActive(true))
        .then(() => navigate(`/app/campaigns/${uuid}/${type}`))
        .catch((errors) => console.info(errors))
    } else {
      navigate(`/app/campaigns/${uuid}/${type}`)
    }
  }

  const buttonClasses = classnames(buttonStyles.outlinedButton, {
    'disabled': !values[name] || !isPreviewActive
  })

  const viewMode = getCodeHighlightMode(bodyType)

  return (
    <div className={styles.codeFieldRow}>
      <Field
        label={label}
        name={name}
        component={FormCodeField}
        viewMode={viewMode}
        disabled={!isPreviewActive}
      />
      {uuid && <button
        onClick={() => onEmailBodyPreviewClick(values[name], bodyType)}
        type="button"
        className={buttonClasses}
      >
        Preview
      </button>}
    </div>

  )
}

EmailBodyField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  bodyType: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default EmailBodyField
