import React from 'react'
import DateTimePicker from 'react-datetime-picker'
import PropTypes from 'prop-types'
import _camelCase from 'lodash/camelCase'
import classnames from 'classnames'
import _get from 'lodash/get'
import dayjs from 'dayjs'
import * as styles from './styles.module.css'

const minDate = dayjs.utc().subtract(1, 'day').toDate()

const DatetimeFormField = (props) => {
  const {
    form: {touched, errors},
    field,
    className,
    setFieldValue,
    ...rest
  } = props
  const {name} = field
  const {label} = rest

  const randSufix = Math.random().toString(36).substring(2, 12)
  const controlID = _camelCase(`${name}ID${randSufix}`)
  const labelID = `${controlID}-label`
  const hasError = !!(_get(touched, name, null) && _get(errors, name, null))

  const componentClasses = classnames(className, styles.field, {
    hasError
  })

  const handleCalendarChange = (val) => setFieldValue(name, val, true)

  return (
    <div className={styles.fieldRow}>
      {label && (
        <label htmlFor={controlID} id={labelID} className={styles.fieldLabel}>
          {label}
        </label>
      )}
      <DateTimePicker
        className={componentClasses}
        format="yyyy-MM-dd HH:mm:ss"
        minDate={minDate}
        onChange={handleCalendarChange}
        value={field.value || dayjs.utc().toDate()}
      />
      {hasError && (
        <div className={styles.fieldError}>
          {_get(errors, name)}
        </div>
      )}
    </div>
  )
}

DatetimeFormField.propTypes = {
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string,
}

export default DatetimeFormField
