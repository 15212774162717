import React, {Fragment, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {useSelector} from 'react-redux'
import {getCampaignById} from 'selectors/campaigns'
import {useInputWithData} from 'hooks/campaign'
import {getCodeHighlightMode} from 'helpers/campaigns'
import * as buttonStyles from 'styles/components/button.module.css'
import * as spacesStyles from 'styles/utils/spaces.module.css'
import {normalizeParams} from './normalizeParams'
import EmailBodyForm from './emailBodyForm'
import EmailBodyIframe from './iframe'
import TemplateDataForm from './templateDataForm'
import * as styles from './styles.module.css'

const BODY_TYPE_MAP = {
  html: 'email_body_html',
  text: 'email_body_text',
  amp: 'email_body_amp'
}

const CampaignEmailBody = ({uuid, type}) => {
  const campaign = useSelector((state) => getCampaignById(state, uuid))
  const {email_subject: emailSubject} = campaign
  const formRef = useRef()

  const emailBody = BODY_TYPE_MAP[type] && campaign?.[BODY_TYPE_MAP[type]] ? campaign[BODY_TYPE_MAP[type]] : null

  const [templateData, setTemplateData] = useState({})
  const [isEmailBodySubmitting, setIsEmailBodySubmitting] = useState(false)

  const onTemplateFormSubmit = (params) => {
    const normalizedTemplateData = normalizeParams(params.templateData)

    setTemplateData(normalizedTemplateData)
  }

  const subjectWithData = useInputWithData(templateData, emailSubject)

  const onEmailBodyFormSubmit = () => {
    if (formRef.current) {
      setIsEmailBodySubmitting(true)
      formRef.current.submitForm()
        .then(() => setIsEmailBodySubmitting(false))
    }
  }

  if (!emailBody) {
    return (
      <Fragment>
        <div className={spacesStyles.spaceBottomSmall}>
          <Link to={`/app/campaigns/edit/${uuid}`}>Go back to edit</Link>
        </div>
        <div>No email body content</div>
      </Fragment>
    )
  }

  const formInitialValue = {
    uuid,
    [BODY_TYPE_MAP[type]]: emailBody
  }

  const bodyViewMode = getCodeHighlightMode(type)

  return (
    <Fragment>
      <div className={spacesStyles.spaceBottomSmall}>
        <Link to={`/app/campaigns/edit/${uuid}`}>Go back to edit</Link>
      </div>
      <div className={styles.headingRow}>
        <h1 className={styles.heading}>Campaign {type} email body</h1>
        <button
          type="submit"
          disabled={isEmailBodySubmitting}
          className={buttonStyles.primaryButton}
          onClick={onEmailBodyFormSubmit}
        >
          Save and Preview
        </button>
      </div>
      <div className={styles.emailBodyGrid}>
        <div className={styles.emailBodyCol}>
          <h3>{emailSubject}</h3>
          <EmailBodyForm
            innerRef={formRef}
            initialValues={formInitialValue}
            name={BODY_TYPE_MAP[type]}
            viewMode={bodyViewMode}
          />
        </div>
        <div className={styles.emailBodyCol}>
          <h3>{subjectWithData}</h3>
          <EmailBodyIframe emailBody={emailBody} type={type} data={templateData}/>
        </div>
        <TemplateDataForm campaign={campaign} onSubmit={onTemplateFormSubmit} />
      </div>
    </Fragment>
  )
}

CampaignEmailBody.propTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default CampaignEmailBody
