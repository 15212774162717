import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {useDispatch} from 'react-redux'
import {addCampaignRequest} from 'actions/campaigns'
import {dispatchInPromise} from 'utils/redux'
import CampaignForm from './form'

const initialValues = {
  name: '',
  period: 'hourly',
  sql_query: '',
  recepient_uniq_key: '',
  recepient_email_key: '',
  email_subject: '',
  email_from: '',
  email_reply_to: '',
  email_body_html: '',
  email_body_text: '',
  email_body_amp: '',
  email_headers: [],
  next_perform_at: dayjs.utc().toDate()
}

const AddCampaign = ({navigate}) => {
  const dispatch = useDispatch()

  const onSubmit = (values, {setSubmitting, setErrors}) => {
    return dispatchInPromise(dispatch, addCampaignRequest, {data: values})
      .finally(() => setSubmitting(false))
      .then(() => navigate('/app', {replace: true}))
      .catch((errors) => setErrors(errors))
  }

  return (
    <div>
      <h1>Add Campaign</h1>
      <CampaignForm
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>
  )
}


AddCampaign.propTypes = {
  navigate: PropTypes.func.isRequired
}

export default AddCampaign
