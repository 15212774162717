import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {fetchCampaignsRequest} from 'actions/campaigns'
import {getIsLoggedIn} from 'selectors/auth'
import {isCampaignsLoaded} from 'selectors/campaigns'
import Login from 'components/login'
import Loader from 'components/loader'

const PrivateRoute = ({component: Component, ...rest}) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getIsLoggedIn)
  const isLoading = useSelector(({campaigns}) => campaigns.isLoading)
  const campaignsLoaded = useSelector(isCampaignsLoaded)

  useEffect(() => {
    if (isLoggedIn && !campaignsLoaded) {
      dispatch(fetchCampaignsRequest())
    }
  }, [dispatch, isLoggedIn, campaignsLoaded])

  if (isLoading || (isLoggedIn && !campaignsLoaded)) {
    return (
      <Loader />
    )
  }

  if (!isLoggedIn) {
    return (
      <Login {...rest} />
    )
  }

  return (
    <Component {...rest} />
  )
}

export default PrivateRoute
