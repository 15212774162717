import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {getRecipientsByCampaignId} from 'selectors/campaigns'
import * as styles from './styles.module.css'
import TablePreview from './tablePreview';

const SqlQueryResult = ({uuid}) => {
  const isLoading = useSelector(({sqlQuery}) => sqlQuery.isLoading)
  const hasError = useSelector(({sqlQuery}) => sqlQuery.hasError)
  const recipients = useSelector((state) => getRecipientsByCampaignId(state, uuid))

  if (isLoading) {
    return (
      <div className={styles.resultContainer}>
        Loading...
      </div>
    )
  }

  if (hasError) {
    return (
      <div className={styles.resultContainer}>
        Something went wrong, the query might be invalid
      </div>
    )
  }

  if (!recipients.length) {
    return (
      <div className={styles.resultContainer}>
        No recipients
      </div>
    )
  }

  return <TablePreview results={recipients} />
}

SqlQueryResult.propTypes = {
  uuid: PropTypes.string.isRequired
}

export default SqlQueryResult
