const JSON_FIELDS_FORMAT_RE = /(.*)_json/i

export const normalizeParams = (params) => (
  params.reduce((agg, item) => {
    if (JSON_FIELDS_FORMAT_RE.test(item.key)) {
      let jsonData = item.value
      try {
        jsonData = JSON.parse(item.value)
      } catch (e) {
        jsonData = item.value
      }
      return {
        ...agg,
        [item.key]: jsonData
      }
    }
    return {
      ...agg,
      [item.key]: item.value
    }
  }, {})
)
