import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {sendTestingEmailRequest} from 'actions/campaigns'
import {Formik, Form, Field, FieldArray} from 'formik'
import FormField from 'components/formField'
import {useDispatch} from 'react-redux'
import {dispatchInPromise} from 'utils/redux'
import * as formStyles from 'styles/components/form.module.css'
import * as buttonStyles from 'styles/components/button.module.css'
import {formValidationSchema} from './templateDataFormValidationSchema'
import {formValidationSchema as sendingFormValidationSchema} from './testSendingFormValidationSchema'
import * as styles from './styles.module.css'
import {normalizeParams} from './normalizeParams'

const DATA_FORM_INITIAL_VALUES = {
  templateData: []
}

const SENDING_FORM_INITIAL_VALUES = {
  email: '',
  additionalItems: []
}

const TemplateDataForm = ({campaign, onSubmit}) => {
  const dispatch = useDispatch()
  const {uuid} = campaign

  const onSubmitTesting = (values, {setSubmitting, setErrors}) => {
    const {email, additionalItems} = values

    const additionalData = normalizeParams(additionalItems)

    const data = {...additionalData, email}

    return dispatchInPromise(dispatch, sendTestingEmailRequest, {uuid, data})
      .finally(() => setSubmitting(false))
      .catch((errors) => setErrors(errors))
  }

  return (
    <div className={styles.dataForm}>
      <Formik
        initialValues={DATA_FORM_INITIAL_VALUES}
        validationSchema={formValidationSchema}
        onSubmit={onSubmit}
      >
        {({values}) => (
          <Form>
            <h3>Email data</h3>
            <FieldArray name="templateData">
              {({remove, push}) => (
                <Fragment>
                  {values['templateData'].length > 0 && (
                    values['templateData'].map((_el, index) => (
                      <div key={index} className={formStyles.keyValueFieldSet}>
                        <Field
                          label="Key"
                          name={`templateData.${index}.key`}
                          type="text"
                          component={FormField}
                        />
                        <Field
                          label="Value"
                          name={`templateData.${index}.value`}
                          type="text"
                          component={FormField}
                        />
                        <div>
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className={formStyles.deleteKeyValueButton}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                  <button
                    type="button"
                    onClick={() => push({key: '', value: ''})}
                    className={styles.addDataButton}
                  >
                    Add data
                  </button>
                </Fragment>
              )}
            </FieldArray>

            <div className={formStyles.formControls}>
              <button type="submit" className={buttonStyles.outlinedButton}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <br />
      <br />
      <Formik
        initialValues={SENDING_FORM_INITIAL_VALUES}
        validationSchema={sendingFormValidationSchema}
        onSubmit={onSubmitTesting}
      >
        {({values}) => (
          <Form>
            <h3>Email testing</h3>
            <Field
              label="Email"
              name="email"
              type="text"
              component={FormField}
            />
            <FieldArray name="additionalItems">
              {({remove, push}) => (
                <Fragment>
                  {values['additionalItems'].length > 0 && (
                    values['additionalItems'].map((_el, index) => (
                      <div key={index} className={formStyles.keyValueFieldSet}>
                        <Field
                          label="Key"
                          name={`additionalItems.${index}.key`}
                          type="text"
                          component={FormField}
                        />
                        <Field
                          label="Value"
                          name={`additionalItems.${index}.value`}
                          type="text"
                          component={FormField}
                        />
                        <div>
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className={formStyles.deleteKeyValueButton}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                  <button
                    type="button"
                    onClick={() => push({key: '', value: ''})}
                    className={styles.addDataButton}
                  >
                    Add data
                  </button>
                </Fragment>
              )}
            </FieldArray>

            <div className={formStyles.formControls}>
              <button type="submit" className={buttonStyles.outlinedButton}>
                Send test examile
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

TemplateDataForm.propTypes = {
  campaign: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default TemplateDataForm
