import React, {useState} from 'react'
import classnames from 'classnames'
import {useSelector, useDispatch} from 'react-redux'
import {fetchSqlQueryResultRequest, qaCampaignRequest} from 'actions/campaigns'
import * as buttonStyles from 'styles/components/button.module.css'
import SqlQueryResult from './queryResult'
import * as styles from './styles.module.css'
import QaResult from './qaResult';

const SqlQueryPreview = ({uuid, query, qaQuery}) => {
  const dispatch = useDispatch()
  const [isSqlQueryResultVisible, setIsSqlQueryResultVisible] = useState(false)
  const [isQaSqlQueryResultVisible, setIsQaSqlQueryResultVisible] = useState(false)

  const isLoading = useSelector(({sqlQuery}) => sqlQuery.isLoading)
  const isQaLoading = useSelector(({qaSqlQuery}) => qaSqlQuery.isLoading)

  const onShowResultClick = () => {
    setIsSqlQueryResultVisible(true)
    setIsQaSqlQueryResultVisible(false)
    dispatch(fetchSqlQueryResultRequest({uuid, query}))
  }

  const onQAClick = () => {
    setIsSqlQueryResultVisible(false)
    setIsQaSqlQueryResultVisible(true)
    dispatch(qaCampaignRequest({uuid, query, qaQuery}))
  }

  const onHideResultClick = () => {
    setIsSqlQueryResultVisible(false)
    setIsQaSqlQueryResultVisible(false)
  }

  const showSqlResultsButtonClasses = classnames(buttonStyles.outlinedButton, {
    'disabled': !query || isLoading
  })
  const showQAResultsButtonClasses = classnames(buttonStyles.outlinedButton, {
    'disabled': !qaQuery || isQaLoading
  })

  const hideResultsButtonClasses = classnames(buttonStyles.outlinedButton, {
    'disabled': isLoading || isQaLoading
  })

  if (!uuid) {
    return null
  }

  return (
    <div>
      <div className={styles.resultControls}>
        <button
          onClick={onShowResultClick}
          type="button"
          className={showSqlResultsButtonClasses}
        >
          {`${isSqlQueryResultVisible ? 'Update' : 'Show'} Result`}
        </button>
        <button
          onClick={onQAClick}
          type="button"
          className={showQAResultsButtonClasses}
        >
          QA campaign
        </button>
        {(isSqlQueryResultVisible || isQaSqlQueryResultVisible) && (
          <button
          onClick={onHideResultClick}
          type="button"
          className={hideResultsButtonClasses}
        >
          Hide Result
        </button>
        )}
      </div>
      {isSqlQueryResultVisible && (
        <SqlQueryResult uuid={uuid} query={query} />
      )}
      {isQaSqlQueryResultVisible && (
        <QaResult uuid={uuid} query={query} />
      )}
    </div>
  )
}

export default SqlQueryPreview
